@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/* react-select styling */
.react-select__placeholder {
  color: #78808c !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.react-select__control {
  box-shadow: none !important;
  padding: 5px 0 !important;
  border: 1px solid #d7d9dd !important;
}

.react-select__single-value {
  color: black !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}
.react-select__control:hover {
  border: 1px solid #78808c !important;
}

.react-react-select__value-container {
  border: 1px solid #78808c !important;
  padding: 8px 16px !important;
}
.react-select__control--is-focused {
  border: 1px solid #5c00c3 !important;
}
.react-select__indicator-separator {
  display: none !important;
}

.react-select__menu {
  border: none !important;
  margin-top: 4px !important;
  background: #ffffff !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
}

.react-select__option {
  padding: 12px 16px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
}
.react-select__option:first-child {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.react-select__option .react-select__option--is-focused {
  background: #f7f2fc !important;
}
.react-select__option.react-select__option--is-selected {
  background: white !important;
}

/* modal styles */
.react-responsive-modal-modal {
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);
  padding: 48px;
  padding-top: 40px;
  border-radius: 8px;
}
